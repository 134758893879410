import * as React from "react";
import Hero from "../components/hero";
import Tail from "../components/tail";
import TextBlock from "../components/text";
import Seo from "../components/seo";
import NaviNext from "../components/NaviNext";
import Header2 from "../components/header/header2";

const Part10 = () => {
  return (
    <Tail>
      <Hero>
        <h1>РАЗДЕЛ 10. <br/>СПРАВОЧНАЯ ИНФОРМАЦИЯ</h1>
      </Hero>
      <TextBlock>
        <Header2>Контактная информация об Обществе</Header2>
        <dl className="attrs">
          <dt>Полное фирменное наименование Общества</dt>
          <dd>Акционерное общество «МОСГАЗ»</dd>
        </dl>
        <dl className="attrs">
          <dt>Сокращенное фирменное наименование Общества</dt>
          <dd>АО «МОСГАЗ»</dd>
        </dl>

        <dl className="attrs">
          <dt>Полное фирменное наименование Общества на английском языке</dt>
          <dd>Joint Stock Company «MOSGAZ»</dd>
        </dl>

        <dl className="attrs">
          <dt>
            Сокращенное фирменное наименование Общества на английском языке
          </dt>
          <dd>JSC «MOSGAZ»</dd>
        </dl>

        <dl className="attrs">
          <dt>Место нахождения Общества</dt>
          <dd>город Москва</dd>
        </dl>

        <dl className="attrs">
          <dt>Адрес (почтовый) Общества</dt>
          <dd>
            105120, Российская Федерация, город Москва, Мрузовский переулок, дом
            11, строение 1
          </dd>
        </dl>

        <dl className="attrs attrs--last">
          <dt>Телефон call-центра</dt>
          <dd>
            <a className="phoneLink" href="tel:+74956606080">
              +7 (495) 660-60-80
            </a>
          </dd>

          <dt>Горячая линия</dt>
          <dd>
            <a className="phoneLink" href="tel:+78007007104">
              8 (800) 70-07-104
            </a>
          </dd>

          <dt>Факс канцелярии</dt>
          <dd>
            <a className="phoneLink" href="tel:+74959178335">
              +7 (495) 917-83-35
            </a>
          </dd>

          <dt>Адрес электронной почты</dt>
          <dd>
            <a href="mailto:info@mos-gaz.ru">info@mos-gaz.ru</a>
          </dd>

          <dt>Адрес веб-сайта Общества в сети Интернет</dt>
          <dd>
            <a href="https://www.mos-gaz.ru">www.mos-gaz.ru</a>
          </dd>
        </dl>

        <Header2>Банковские реквизиты Общества</Header2>

        <dl className="attrs attrs--last">
          <dt>Наименование банка</dt>
          <dd>Банк ВТБ (ПАО) г. Москва</dd>

          <dt>Расчетный счет</dt>
          <dd>40602810500160000005</dd>

          <dt>Корреспондентский счет</dt>
          <dd>30101810700000000187 в ГУ Банка России по ЦФО</dd>

          <dt>БИК</dt>
          <dd>044525187</dd>
        </dl>

        <Header2>Информация о реестродержателе Общества</Header2>

        <dl className="attrs">
          <dt>Полное фирменное наименование</dt>
          <dd>Общество с ограниченной ответственностью «Реестр-РН»</dd>
        </dl>

        <dl className="attrs">
          <dt>Сокращенное фирменное наименование</dt>
          <dd>ООО «Реестр-РН»</dd>
        </dl>

        <dl className="attrs">
          <dt>Место нахождения</dt>
          <dd>Российская Федерация, г. Москва</dd>
        </dl>

        <dl className="attrs">
          <dt>Адрес</dt>
          <dd>109028, г. Москва, пер. Хохловский, д. 13, стр. 1, пом. 22</dd>
        </dl>

        <dl className="attrs">
          <dt>Почтовый адрес</dt>
          <dd>115172, г. Москва, а/я 4</dd>

          <dt>Телефон</dt>
          <dd>
            <a className="phoneLink" href="tel:+74954117911">
              +7 (495) 411-79-11
            </a>
          </dd>

          <dt>Факс</dt>
          <dd>
            <a className="phoneLink" href="tel:+74954118312">
              +7 (495) 411-83-12
            </a>
          </dd>
        </dl>

        <p>
          Данные о лицензии на осуществление деятельности по ведению реестра
          владельцев ценных бумаг
        </p>

        <dl className="attrs attrs--last">
          <dt>Номер</dt>
          <dd>10-000-1-00330</dd>

          <dt>Дата выдачи</dt>
          <dd>16.12.2004</dd>

          <dt>Дата окончания действия</dt>
          <dd>бессрочная</dd>

          <dt>Наименование органа, выдавшего лицензию</dt>
          <dd>ФСФР России</dd>
        </dl>

        <Header2>Информация о номинальном держателе</Header2>

        <p>
          Обыкновенные именные бездокументарные акции Общества, принадлежащие
          городу Москве в лице Департамента городского имущества города Москвы,
          учитываются на счете номинального держателя – Небанковской кредитной
          организации акционерном обществе «Национальный расчетный депозитарий».
        </p>

        <Header2>Информация об Аудиторе Общества</Header2>

        <dl className="attrs">
          <dt>Полное фирменное наименование</dt>
          <dd>Общество с ограниченной ответственностью «Аудит Анлимитед»</dd>
        </dl>

        <dl className="attrs">
          <dt>Сокращенное фирменное наименование</dt>
          <dd>ООО «Аудит Анлимитед»</dd>
        </dl>

        <dl className="attrs">
          <dt>Место нахождения</dt>
          <dd>
            109382, г. Москва, ул. Люблинская, д. 141, этаж 4, помещ./часть ком.
            V/45
          </dd>
        </dl>

        <dl className="attrs attrs--last">
          <dt>Телефон</dt>
          <dd>
            <a className="phoneLink" href="tel:+74999388400">
              +7 (499) 938-84-00
            </a>
          </dd>

          <dt>Адрес веб-сайта в сети Интернет</dt>
          <dd>
            <a href="https://www.auditunlimited.ru">www.auditunlimited.ru</a>
          </dd>
        </dl>

        <Header2>Информация о независимых оценщиках Общества</Header2>

        <dl className="attrs">
          <dt>Полное фирменное наименование</dt>
          <dd>Общество с ограниченной ответственностью «ВАЛНЕТ-ЦЕНТР»</dd>
        </dl>

        <dl className="attrs">
          <dt>Сокращенное фирменное наименование</dt>
          <dd>ООО «ВАЛНЕТ-ЦЕНТР»</dd>
        </dl>

        <dl className="attrs">
          <dt>Адрес</dt>
          <dd>115432, Москва, улица Трофимова, дом 2/1, помещение 3</dd>

          <dt>ОГРН</dt>
          <dd>1097746404733</dd>

          <dt>ИНН</dt>
          <dd>7725672919</dd>
        </dl>

        <dl className="attrs">
          <dt>Полное фирменное наименование</dt>
          <dd>
            Общество с ограниченной ответственностью «ЦЕНТР ОЦЕНКИ
            СОБСТВЕННОСТИ»
          </dd>
        </dl>

        <dl className="attrs">
          <dt>Сокращенное фирменное наименование</dt>
          <dd>ООО «ЦОС»</dd>
        </dl>

        <dl className="attrs attrs--last">
          <dt>Адрес</dt>
          <dd>
            123056, город Москва, улица Красина, дом 27, строение 2, этаж 4,
            помещение XXXIV, комната 3-8
          </dd>

          <dt>ОГРН</dt>
          <dd>1027700560656</dd>

          <dt>ИНН</dt>
          <dd>7704205198</dd>
        </dl>

        <p className="text-sm">
          Настоящий годовой отчет по итогам 2021 года (далее – Годовой отчет)
          подготовлен с использованием информации, доступной акционерному
          обществу «МОСГАЗ» (далее также – АО «МОСГАЗ», Общество) на момент его
          составления. Некоторые заявления, содержащиеся в настоящем Годовом
          отчете, являются заявлениями, содержащими прогноз относительно будущих
          событий.
        </p>
        <p className="text-sm">
          В связи с чем Общество предупреждает, что фактические результаты или
          развитие тех или иных событий могут отличаться от прогнозных
          заявлений, содержащихся в настоящем Годовом отчете. За исключением
          случаев, предусмотренных законодательством, Общество не берет на себя
          обязательств по пересмотру или подтверждению ожиданий и оценок, а
          также по публикации обновлений и изменений прогнозных заявлений
          Годового отчета в связи с последующими событиями или поступлением
          новой информации. Информация о руководстве Общества представлена в
          соответствии с Федеральным законом «О персональных данных» от
          27.07.2006 № 152-ФЗ.
        </p>

        <NaviNext
          next="/app-1"
          title="ПРИЛОЖЕНИЕ 1. К ГОДОВОМУ ОТЧЕТУ АО «МОСГАЗ» ЗА 2021 ГОД"
        />
      </TextBlock>
    </Tail>
  );
};

export default Part10;

export const Head = () => <Seo title="РАЗДЕЛ 10. СПРАВОЧНАЯ ИНФОРМАЦИЯ" />;
